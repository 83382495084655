import { Controller } from "@hotwired/stimulus"
import AirDatepicker from 'air-datepicker'
import localeRu from 'air-datepicker/locale/ru'

export default class extends Controller {
  connect() {
    this.picker = new AirDatepicker(this.element, {
      minDate: '01.01.1900',
      maxDate: new Date(),
      locale: localeRu,
      autoClose: true
    })
  }
}
